
import { defineComponent } from "vue";
import {
  IonMenu,
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonIcon,
  IonBadge,
  IonToolbar,
  IonTitle,
  IonHeader,
  IonContent,
  IonFooter
} from "@ionic/vue";

import {
  personCircle,
  menuOutline,
  homeOutline,
  settingsOutline,
  listOutline,
  airplaneOutline,
  logOutOutline
} from "ionicons/icons";

import Flight from "../../model/Flight";

export default defineComponent({
  name: "MainMenu",
  components: {
    IonMenu,
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonIcon,
    IonBadge,
    IonToolbar,
    IonTitle,
    IonHeader,
    IonContent,
    IonFooter
  },
  props: {
    contentId: String
  },
  setup() {
    return {
      personCircle,
      menuOutline,
      homeOutline,
      settingsOutline,
      listOutline,
      airplaneOutline,
      logOutOutline
    };
  },
  data() {
    let flights: Flight[] = [];
    return {
      flights: flights
    };
  },
  methods: {
    willOpen: function() {
      console.log("yeah");
      try {
        const data = localStorage.getItem("flights");
        this.flights = [];
        console.log(data);

        if (data !== null) {
          let t = JSON.parse(data);
          for(let f of t) {
					  this.flights.push(Flight.fromJSON(f));
				  }

          console.log(this.flights);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
});
