

import { defineComponent } from "vue";

import { IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonList,
    IonItem, IonCard, IonCardTitle, IonCardContent, IonCardHeader, IonInput, IonLabel,
    IonBadge, IonIcon,
    IonButtons,
    IonButton,
    IonSpinner,
    modalController
} from '@ionic/vue';

import { searchOutline } from 'ionicons/icons';

import { format, parseISO, differenceInMilliseconds } from 'date-fns';
import Flight from '@/model/Flight';
import RouteparserService from '@/service/RouteparserService';

import { Airport, FAFlight } from '@/model/Flightaware';

export default defineComponent({
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonItem,
        IonCard, IonCardTitle,
        IonCardContent,
        IonCardHeader,
        IonInput,
        IonLabel,
        IonBadge,
        IonIcon,
        IonButtons,
        IonButton,
        IonSpinner
    },
    setup() {
        return { searchOutline };
    },
    data() {
        return {
            scheduledFlights: [] as FAFlight[] ,
            addFlights: [] as string[],

            searchTerm: '',
            flightsLoaded: false
        };
    },
    mounted() {
        this.fetchData();
    },
    computed: {
        scheduledFlightsFiltered() {
            console.log('searchTerm ',this.searchTerm);
            if (this.searchTerm.length === 0) {
                console.log('no search term');
                return this.scheduledFlights;
            }

            const terms = this.searchTerm.split(' ');

            return this.scheduledFlights.filter((flight :FAFlight) => {
                // return if it's the flight number
                if (flight.ident_icao.toLowerCase().includes(this.searchTerm.toLowerCase())) return true;

                let found = 0;
                for (const term of terms) {
                    if (flight.origin.code_icao.toLowerCase().includes(term.toLowerCase())) {
                        found++;
                        continue;
                    }
                    if (flight.destination.code_icao.toLowerCase().includes(term.toLowerCase())) {
                        found++;
                        continue;
                    }

                    if (flight.origin.code_iata.toLowerCase().includes(term.toLowerCase())) {
                        found++;
                        continue;
                    }

                    if (flight.destination.code_iata.toLowerCase().includes(term.toLowerCase())) {
                        found++;
                        continue;
                    }

                    found = 0;
                }

                return found === terms.length;
            });
        },
    },
    methods: {
        fetchData() {
            /*
            fetch("/assets/data/dummy_scheduled.json")
            .then((response) => response.json())
            .then((data) => {
                this.scheduledFlights = data.scheduled; // Store the fetched data in the flights property

                this.flightsLoaded = true;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
            //*/

            //*
            fetch(`${process.env.VUE_APP_BASE_URL}/api/v1/flights/EZS`, {
				method: 'GET',
				mode: 'cors',
				headers: {
                    'Access-Control-Allow-Origin': process.env.VUE_APP_accessControlOrigin//'http://10.8.0.10:8082/'//process.env.VUE_APP_accessControlOrigin
                },
				credentials: 'include',
			})
            .then((response) => response.json())
            .then((data) => {
                this.scheduledFlights = data.scheduled; // Store the fetched data in the flights property

                this.flightsLoaded = true;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
            //*/
        },
        toggleFlight(flightId :string) {
            console.log('toggling ', flightId);
            if (this.addFlights.includes(flightId)) {
                this.addFlights.splice(this.addFlights.indexOf(flightId), 1)
            } else {
                this.addFlights.push(flightId);
                console.log(this.addFlights);
            }
        },
        cancel() {
            return modalController.dismiss(null, "cancel");
        },
        confirm() {
            const flights = [];
            for (const flightId of this.addFlights) {
                const flight = this.scheduledFlights.find((f) => f.fa_flight_id === flightId);
                if (flight) {
                    let f = new Flight();
                    f.flightnumber = flight.ident_icao;
                    f.flightID = flight.fa_flight_id;
                    f.setRouting(`${flight.origin.code_icao} ${flight.destination.code_icao}`, []);
                    f.depatureAirport = flight.origin.code_icao;
                    f.destinationAirport = flight.destination.code_icao;

                    // get coords
                    f.departureAirportCoords = RouteparserService.getAirportCoords(f.depatureAirport);
                    f.destinationAirportCoords = RouteparserService.getAirportCoords(f.destinationAirport);

                    f.std = flight.scheduled_out;
                    f.sta = flight.scheduled_in;
                    f.setBlocktime(Math.floor(differenceInMilliseconds(parseISO(flight.scheduled_in), parseISO(flight.scheduled_out))/1000/60));
                    f.setFlightTime(Math.floor(differenceInMilliseconds(parseISO(flight.estimated_on), parseISO(flight.estimated_off))/1000/60));

                    f.setFlightawareData(flight);

                    console.log(f);
                    flights.push(f);
                }
            }
            return modalController.dismiss(flights, "confirm");
        },
        depatureTimeDiff(flightId :string) {
            const flight = this.scheduledFlights.find((f) => f.fa_flight_id === flightId);
            if (!flight) return 0;

            const diff = differenceInMilliseconds(parseISO(flight.scheduled_out), parseISO(flight.estimated_out))/1000/60;

            if (diff > 5) {
                return diff;
            } else {
                return 0;
            }
        }
    },
});
