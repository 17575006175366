
import {
  IonContent,
  IonHeader, IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem, IonLabel, IonInput, IonSpinner,
  IonGrid, IonRow, IonCol,
  IonMenuButton
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from 'vue-router';

import { personCircle, menuOutline } from "ionicons/icons";

import MainMenu from "./components/MainMenu.vue";

import AuthService from '../service/AuthService';

export default defineComponent({
  name: "LoginPage",
  components: {
    IonContent,
    IonHeader, IonFooter,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
	IonMenuButton,
	IonItem, IonLabel, IonInput, IonSpinner,
	IonGrid, IonRow, IonCol,
    MainMenu
  },
  setup() {
	const router = useRouter();
    return { personCircle, menuOutline, router };
  },
  data() {
	return {
		user: '',
		password: '',
		loading: false
	};
  },
  methods: {
	login() {
		if (this.loading) {
			return;
		}
		
		if ((this.$refs.loginForm as HTMLFormElement).checkValidity()) {
			this.loading = true;

			AuthService.login(this.user, this.password).then(result => {
				if (result) {
					// redirect
					this.router.push('/home');
					this.loading = false;
				} else {
					// error
					this.loading = false;
				}
			});
		}
	  }
  }
});
