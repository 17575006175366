
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonMenuButton,
	IonFooter,
	IonRange,
	IonBadge,
	IonLabel,
	RangeCustomEvent,
	toastController
} from "@ionic/vue";
import { defineComponent } from "vue";

import { personCircle,menuOutline,homeOutline,settingsOutline,listOutline,airplaneOutline,alertCircleOutline } from "ionicons/icons";

import MainMenu from './components/MainMenu.vue';
import Flight from '../model/Flight';
import Waypoint from '../model/Waypoint';

import { useRoute } from 'vue-router';

import { Map,tileLayer,TileLayer, Layer, geoJSON, Control,marker,polyline,polygon,LatLng,control, icon, easyButton, polylineDecorator, GeoJSON, StyleFunction } from "leaflet";
import { GeoJSONFeature } from '@/model/NOP';
import * as L from 'leaflet';
import 'leaflet-easybutton';
import 'leaflet-polylinedecorator';

import AuthService from '../service/AuthService';
import { GeoJSONOptions } from "leaflet";

export default defineComponent({
	name: "MapPage",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonButtons,
		IonMenuButton,
		IonFooter,
		IonRange,
		IonBadge,
		IonLabel,
		MainMenu
	},
	setup() {
		const route=useRoute();
		const { id }=route.params;
		let map: Map = Map.prototype;
		let rain: TileLayer=TileLayer.prototype;
		let startTime = 0;
		
		let rainLayer :TileLayer[] = [];
		let tacticalDelaysLayer :GeoJSON = new GeoJSON();

		// retrieve flight
		let flights: Flight[] = [];
		try {
			const data=localStorage.getItem("flights");
			console.log(data);

			if(data!==null) {
				flights=JSON.parse(data);
			}
		} catch(e) {
			console.error(e);
		}
		
		const flight :Flight = flights[Number(id)];
		
		//
		const routePlaneMarker = L.Symbol.marker(
						{
							rotate: true,
							markerOptions: {
                    			icon: L.icon({
                        		iconUrl: '/assets/img/plane_route.png',
                        		iconSize: [32, 32],
                        		iconAnchor: [16, 16]})
                			}
                	});

		return { id,personCircle,menuOutline,homeOutline,settingsOutline,listOutline,airplaneOutline,map,rain, rainLayer, tacticalDelaysLayer, flight, startTime, routePlaneMarker, alertCircleOutline};
	},
	data() {
		return {
			
			loading: true,
			d: 0,
			maxZoom: 5,
			owmApiKey: '06aac0fd4ba239a20d824ef89602f311',
			//rain: tileLayer.prototype
			pathAircraft: polylineDecorator.prototype,

			readyMessage: false,
			ctotReceived: false
		};
	},
	mounted() {
		console.log('mounted');
	},
	beforeUnmount() {
		console.log('beforeUnmount');
	},
	ionViewWillLeave() {
		console.log('ionViewWillLeave');
	},
	ionViewDidLeave() {
		console.log('ionViewDidLeave');
	},
	ionViewWillEnter() {
		console.log('ionViewWillEnter');

		this.setupMap();
	},
	ionViewDidEnter() {
		console.log('ionViewDidEnter');

		this.loadFlight();
	},
	computed: {
		ionRangeMax() {
			let maxFlightTime = this.flight.blocktime + 15 - Math.ceil(this.flight.blocktime%15);
			maxFlightTime += 8*15 + 8*15;
			console.log(this.flight.blocktime, maxFlightTime)
			return maxFlightTime;
		}
	},
	methods: {
		setupMap() {
			this.map = new Map(this.$refs.mapcontainer as HTMLElement).setView([8,50],7);

			this.map.attributionControl.setPrefix('Airliner Briefing');
			//this.map.setView([8, 50], 7);

			this.map.createPane('rainPane');

			//let maxZoom = 7;
			//let owmApiKey = "06aac0fd4ba239a20d824ef89602f311";

			// add the OpenStreetMap tiles
			tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",{
				maxZoom: this.maxZoom,
				minZoom: 5,
				attribution:
					'&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>'
			}).addTo(this.map);

			// show the scale bar on the lower left corner
			new Control.Scale({ imperial: true,metric: true}).addTo(this.map);
		},
		loadFlight() {
			/*
			let d=1660363200;
			this.d=Math.floor(Date.now()/1000);
			this.d=this.d-this.d%(15*60);
			this.startTime=this.d;
			//*/
			
			const dateSTD = new Date(this.flight.std);
			let timestamp = Math.floor(dateSTD.getTime()/1000);
			this.d = timestamp - timestamp%(15*60);
			this.startTime = this.d;

			/* // map1.0-clouds
			let clouds = tileLayer(
			`https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=${this.owmApiKey}&date=${this.d}`,
			{
				maxZoom: this.maxZoom,
				attribution:
				'&copy; <a href="http://openweathermap.org">OpenWeatherMap</a>',
				opacity: 0.5
			}
			).addTo(this.map);
			//*/

			/* // map1.0-rain
				this.rain = tileLayer(
				`https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=${this.owmApiKey}&date=${this.d}`,
				{
					maxZoom: this.maxZoom,
					attribution:
					'&copy; <a href="http://openweathermap.org">OpenWeatherMap</a>',
					opacity: 0
				}
				).addTo(this.map);//*/

			let op='PR0';
			let key2='63d94100b534be3d1770c3f66bdd2a48';

			/* // owm is disabled
			let wind = tileLayer(
			`https://maps.openweathermap.org/maps/2.0/weather/WND/{z}/{x}/{y}?appid=${key2}&use_norm=false&arrow_step=16`,
			{
				maxZoom: this.maxZoom,
				attribution:
				'&copy; <a href="http://openweathermap.org">OpenWeatherMap</a>',
				opacity: 0.5
			}
			).addTo(this.map);
			//*/

			/*
			this.rain=tileLayer(
				//`https://maps.openweathermap.org/maps/2.0/weather/${op}/{z}/{x}/{y}?appid=${key2}&date=${this.d}`,
				//`http://10.8.0.10:8001/api/v1/owm/maps/${op}/{z}/{x}/{y}/${this.d}`,
				`${AuthService.baseURL}/api/v1/owm/maps/${op}/{z}/{x}/{y}/${this.d}`,
				{
					maxZoom: this.maxZoom,
					pane: 'rainPane',
					opacity: 0.7
				}
			).addTo(this.map);
			//*/
			
			//this.loadRainlayer();

			// adding marker
			const depIcon = icon({
				iconUrl: '/assets/img/plane_depature.png',
			
				iconSize:     [42, 42], // size of the icon
				iconAnchor:   [21, 42], // point of the icon which will correspond to marker's location
				popupAnchor:  [-3, -15] // point from which the popup should open relative to the iconAnchor
			});
			
			const destIcon = icon({
				iconUrl: '/assets/img/plane_destination.png',
			
				iconSize:     [42, 42], // size of the icon
				iconAnchor:   [21, 42], // point of the icon which will correspond to marker's location
				popupAnchor:  [-3, -15] // point from which the popup should open relative to the iconAnchor
			});

			// show a marker on the map
			// depature airport
			marker(this.flight.departureAirportCoords, {icon: depIcon})
				.bindPopup(this.flight.depatureAirport)
				.addTo(this.map);
			
			// destination
			marker(this.flight.destinationAirportCoords, {icon: destIcon})
				.bindPopup(this.flight.destinationAirport)
				.addTo(this.map);
				
			easyButton('<i class="fa-solid fa-layer-group"></i>', (btn, map) => {
				//alert('loading rain layers');
				this.loadRainlayer();
			}).addTo(this.map);
			
			easyButton('<i class="fa-regular fa-message"></i>', (btn, map) => {
				// demo functionality
				if (!this.ctotReceived) {
					this.addCTOT();
				} else {
					this.presentToast('Ready message sent', 'success');
					this.readyMessage = true;
				}

			}).addTo(this.map);
			
			easyButton('<i class="fa-solid fa-draw-polygon"></i>', (btn, map) => {
				/*
				console.log('adding fake regulated areas');
				let latlngs : LatLng[] = [new LatLng(46.099994, -1.428316),new LatLng(43.390984, 0.981802),new LatLng(44.576203, 3.983942),new LatLng(46.559108, 2.899590)];

				let poly = polygon(latlngs, {color: '#46b0e0'}).addTo(this.map);
				//*/

				// https://www.public.nm.eurocontrol.int/PUBMAP/gateway/spec/wfs?service=WFS&request=GetFeature&version=1.1.0&outputFormat=application%2Fgeojson&typeName=Tactical_Delays
				/*
					state: N - blau
					state: M - orange
					state: L - gelb
					state: H - rot
				*/
				this.displayRegulatedAreas();
			}).addTo(this.map);
			
			easyButton('<i class="fa-solid fa-timeline"></i>', (btn, map) => {
				// toggle EOBT, CTOT etc.
			}).addTo(this.map);

			let ofpRoutingPoints: LatLng[]=[];
			let waypoints = this.flight.waypoints;
			
			// add depature airport coords 
			waypoints.unshift(Waypoint.convertArrayToWpt(this.flight.depatureAirport, this.flight.departureAirportCoords));
			
			// add destination airport coords
			waypoints.push(Waypoint.convertArrayToWpt(this.flight.destinationAirport, this.flight.destinationAirportCoords));
			
			for (let wpt of waypoints) {
				if (wpt.lat === null || wpt.lon === null) {
					continue;
				}
				ofpRoutingPoints.push(new LatLng(wpt.lat, wpt.lon));
				/*
				marker(new LatLng(wpt.lat, wpt.lon))
				.bindPopup(`${wpt.name} ${wpt.lat} ${wpt.lon}`)
				.addTo(this.map);
				//*/
			}
			
			console.log(ofpRoutingPoints);

			let pl=polyline(ofpRoutingPoints,{
				color: 'orange',
				weight: 3,
				opacity: 0.9,
				smoothFactor: 1
			}).addTo(this.map);
			
			this.pathAircraft = L.polylineDecorator(pl, {
				patterns: [
					{
						offset: '0%',
						repeat: '110%',
						symbol: this.routePlaneMarker
					}
				]
			}).addTo(this.map);
		
			let prevLatLng :LatLng ;
			let sum = 0;
			ofpRoutingPoints.forEach((latLng: LatLng) => {
				if(prevLatLng) {
					console.log('distance: ', prevLatLng.distanceTo(latLng));
					sum += prevLatLng.distanceTo(latLng);
				}

				prevLatLng = latLng;
			});
			console.log(`Total distance ${Math.floor(sum/1000)}km or ${Math.floor(sum*0.000539957)}NM`);
			
			

			//*
			setTimeout(() => {
				try {
					this.map.invalidateSize();
					this.map.fitBounds(pl.getBounds());
				} catch(e) {
					console.error(e);
				}
			},1500);
		},
		timeFormatter(value: number) {
			let hh=Math.floor(value/60);
			let mm=value%60;
			
			let date = new Date(this.startTime*1000);
			hh += date.getUTCHours();
			
			const hours=hh<10? '0'+hh:''+hh;
			const minutes=mm<10? '0'+mm:''+mm;

			return `${hours}:${minutes}`;
		},
		toHourMinutes: (isoDate: string) => {
			console.log(isoDate);
			let d=new Date(isoDate);
			const hours=d.getHours()<10? '0'+d.getHours():''+d.getHours();
			const minutes=d.getMinutes()<10? '0'+d.getMinutes():''+d.getMinutes();

			return `${hours}:${minutes}`;
		},
		loadRainlayer() {
			let op='PR0';
			
			let maxRange = this.ionRangeMax;
			
			for (let i = 0; i <= maxRange; i+=15) {
				let d = this.startTime+i*60;
				
				// create tile
				const tile = tileLayer(
					`${AuthService.baseURL}/api/v1/owm/maps/${op}/{z}/{x}/{y}/${d}`,
					{
						maxZoom: this.maxZoom,
						pane: 'rainPane',
						opacity: 0// don't show it
					}
				);
				
				// add to map
				tile.addTo(this.map);
				
				// add to array
				this.rainLayer[i] = tile;
			}
			
			// display the first one
			this.rainLayer[0].setOpacity(0.7);
		},
		hideRainlayer() {
			this.rainLayer.forEach((tile :TileLayer) => {tile.setOpacity(0);});
		},
		showRainlayer(id :number) {
			this.hideRainlayer();

			if (typeof this.rainLayer[id] !== 'undefined') {
				this.rainLayer[id].setOpacity(0.7);
			}
		},
		rangeUpdate(event: CustomEvent) {
			console.log(event.detail,this.d,this.rain);
			let val = Number(event.detail.value);

			this.d=this.startTime+val*60;

			let maxRange = this.ionRangeMax;
			let partial = Math.min(((val-120)/(maxRange-240))*100, 100);
			if (val <= 120) {
				partial = 0;
			}
			this.pathAircraft.setPatterns([
		        {
		            offset: `${partial}%`,
		            repeat: '100%',
		            symbol: this.routePlaneMarker
                }
		    ]);
			
			this.showRainlayer(val);

			this.tacticalDelaysLayer.setStyle((feature: any) => {
				let result = {color: "#46b0e0", fillColor: "#46b0e0", opacity: 0, fillOpacity: 0};

				if ((feature.properties.period.min <= this.d*1000 && feature.properties.period.max >= this.d*1000)) {
					result.opacity = 0.8;
					result.fillOpacity = 0.4;
				}

				switch (feature.properties.state) {
					case 'N':
						result.color = "#46b0e0";
						result.fillColor = "#46b0e0";
					break;
					case 'M':
						result.color = "#f0a500";
						result.fillColor = "#f0a500";
					break;
					case 'L':
						result.color = "#f0e500";
						result.fillColor = "#f0e500";
					break;
					case 'H':
						result.color = "#df2c14";
						result.fillColor = "#df2c14";
					break;
				}

				return result;
			});
		},
		async presentToast(text: string, color = 'danger') {
			const toast=await toastController.create({
				message: text,
				duration: 1500,
				position: 'top',
				color: color,
				icon: alertCircleOutline
			});

			await toast.present();
		},
		addCTOT() {
			this.ctotReceived = true;
			this.presentToast('New CTOT 04:44 UTC', 'danger');
		},
		async displayRegulatedAreas() {
			const response = await fetch(`${AuthService.baseURL}/api/v1/regulations`, {
				method: 'GET',
				mode: 'cors',
				headers: {},
				credentials: 'include',
			});
			const data = await response.json();
			
			// tacticalDelaysLayer
			this.tacticalDelaysLayer = geoJSON(data, {
				style: (feature :any) => {
					let result = {color: "#46b0e0", fillColor: "#46b0e0", opacity: 0, fillOpacity: 0};

					if ((feature.properties.period.min <= this.d*1000 && feature.properties.period.max >= this.d*1000)) {
						result.opacity = 0.8;
						result.fillOpacity = 0.4;
					}

					switch (feature.properties.state) {
						case 'N':
							result.color = "#46b0e0";
							result.fillColor = "#46b0e0";
						break;
						case 'M':
							result.color = "#f0a500";
							result.fillColor = "#f0a500";
						break;
						case 'L':
							result.color = "#f0e500";
							result.fillColor = "#f0e500";
						break;
						case 'H':
							result.color = "#df2c14";
							result.fillColor = "#df2c14";
						break;
					}

					return result;
				},
				pointToLayer: function (feature: GeoJSONFeature, latlng: LatLng) {
					return L.circleMarker(latlng, {
						radius: 4,
						fillColor: "#ff7800",
						color: "#000",
						weight: 1,
						opacity: 0.5,
						fillOpacity: 0.4
					});
				}
			}).addTo(this.map);
		}
	}
});
