import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68aadf64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-page" }
const _hoisted_2 = { ref: "flightplan" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "medium",
                  onClick: _ctx.cancel
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Cancel")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode("New Flight information")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.confirm }, {
                  default: _withCtx(() => [
                    _createTextVNode("Add Flight")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createElementVNode("form", _hoisted_2, [
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "stacked" }, {
                default: _withCtx(() => [
                  _createTextVNode("OFP Routing")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                modelValue: _ctx.routing,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.routing) = $event)),
                modelModifiers: { lazy: true, trim: true },
                placeholder: "Copy your routing"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "stacked" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Departure")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.flight.depatureAirport,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.flight.depatureAirport) = $event)),
                            modelModifiers: { lazy: true, trim: true },
                            placeholder: "Departure IATA",
                            class: "ion-text-uppercase",
                            required: true
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "stacked" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Destination")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.flight.destinationAirport,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.flight.destinationAirport) = $event)),
                            modelModifiers: { lazy: true, trim: true },
                            placeholder: "Destination IATA",
                            class: "ion-text-uppercase"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "stacked" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Alternate #1")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.flight.alternate1,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.flight.alternate1) = $event)),
                            modelModifiers: { lazy: true, trim: true },
                            placeholder: "Alternate IATA",
                            class: "ion-text-uppercase",
                            required: true
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "stacked" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Alternate #2")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.flight.alternate2,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.flight.alternate2) = $event)),
                            modelModifiers: { lazy: true, trim: true },
                            placeholder: "Alternate IATA",
                            class: "ion-text-uppercase"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "stacked" }, {
                            default: _withCtx(() => [
                              _createTextVNode("STD")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_datetime_button, { datetime: "flight-std" }),
                          _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_datetime, {
                                modelValue: _ctx.flight.std,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.flight.std) = $event)),
                                id: "flight-std",
                                presentation: "time"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "stacked" }, {
                            default: _withCtx(() => [
                              _createTextVNode("STA")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_datetime_button, { datetime: "flight-sta" }),
                          _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_datetime, {
                                modelValue: _ctx.flight.sta,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.flight.sta) = $event)),
                                id: "flight-sta",
                                presentation: "time"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "stacked" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Flighttime")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_datetime_button, { datetime: "flight-time" }),
                          _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_datetime, {
                                id: "flight-time",
                                presentation: "time",
                                modelValue: _ctx.flight.flighttime,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.flight.flighttime) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "stacked" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Search/Tidy")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_select, {
                            modelValue: _ctx.flight.securitySearch,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.flight.securitySearch) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_select_option, { value: "search" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Cabin Search")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_select_option, { value: "tidy" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Cabin Tidy")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 512)
      ]),
      _: 1
    })
  ]))
}