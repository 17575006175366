import Dexie from 'dexie';

class DatabaseService extends Dexie {
    private static instance: DatabaseService;

    waypoints: Dexie.Table<IWaypoint, string>;
    airports: Dexie.Table<IAirport, string>;

    private constructor() {
        super('AirlinerBriefingDatabase');
        this.version(1).stores({
            waypoints: 'gUID, type, ident, name, lat, lon',
            airports: 'ident, name, lat, lon',
        });

        this.waypoints = this.table('waypoints');
        this.airports = this.table('airports');

        this.setup();
    }

    public static getInstance(): DatabaseService {
        if (!DatabaseService.instance) {
            DatabaseService.instance = new DatabaseService();
        }
        return DatabaseService.instance;
    }

    public getWaypointByIdent(ident: string): Promise<IWaypoint | undefined> {
        return this.waypoints.get({ident: ident});
    }

    public getAirportByIdent(ident: string): Promise<IAirport | undefined> {
        return this.airports.get({ident: ident});
    }

    async setup(): Promise<void> {
        try {
            if (await this.airports.count() === 0) {
                const airportsResponse = await fetch('/assets/data/airports.json');
                const airportsData = await airportsResponse.json();

                const airports: IAirport[] = [];
                Object.entries(airportsData).forEach(([key, airport]: [string, any]) => {
                    airports.push({
                        ident: key,
                        name: airport.name,
                        lat: airport.lat,
                        lon: airport.lon
                    });
                });

                console.log(airports);
            
                if (await this.airports.count() === 0) {
                    console.log('Importing airports');
                    await this.airports.bulkPut(airports);
                }
            }

            console.log(await this.waypoints.count());
            if (await this.waypoints.count() === 0) {
                const waypointsResponse = await fetch('/assets/data/import_waypoints_north-east.json');
                const waypointsData = await waypointsResponse.json();
            
                await this.waypoints.bulkPut(waypointsData);
                console.log(waypointsData.length, 'waypoints imported');
            }
        } catch (error) {
            console.error('Error setting up the database:', error);
        }
    }
}

export default DatabaseService.getInstance();

export interface IWaypoint {
    gUID: string;
    type: string;
    ident: string;
    name: string;
    lat: string;
    lon: string;
}

export interface IAirport {
    ident: string;
    name: string;
    lat: string;
    lon: string;
}