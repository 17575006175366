export default class AuthService {
	public static baseURL = process.env.VUE_APP_BASE_URL;//(process.env.NODE_ENV === 'production') ? 'https://airlinerbriefing.com' : 'http://10.8.0.10:8001';
	private static accessControlOrigin = process.env.VUE_APP_accessControlOrigin;//(process.env.NODE_ENV === 'production') ? 'https://app.airlinerbriefing.com' : 'http://10.8.0.10:8080';

	public static async login(user: string, password: string) {
		console.log(`.env: ${process.env.VUE_APP_BASE_URL} ${process.env.VUE_APP_accessControlOrigin}`)
		//*
		const response = await fetch(`${this.baseURL}/api/v1/entrance/login`, {
			method: 'PUT',
			mode: 'cors',
			headers: {
				//'Access-Control-Allow-Credentials': true,
				'Access-Control-Allow-Origin': this.accessControlOrigin
			},
			credentials: 'include',
			body: JSON.stringify({
				emailAddress: user,
				password: password,
				rememberMe: true
			})
		});

		console.log(response);

		if (response.status == 200) {
			
			return true;
		}

		return false;
	}

	public static async isAuthenticated() {
		// short cut if we authenticated within the last 24h
		const isAuthenticated = localStorage.getItem('isAuthenticated');
		if (isAuthenticated !== null && isAuthenticated === 'true') {
			// check time
			if (localStorage.getItem('authenticatedAt') !== null) {
				const authAt = ''+localStorage.getItem('authenticatedAt');
				const authenticatedAt = parseInt(authAt);

				if (authenticatedAt + 24*60*60*1000 >= Date.now()) {
					return true;
				}
			}
		}

		// TODO add offline check and skip the request
		try {
			const controller = new AbortController();
			setTimeout(() => controller.abort(), 1000);
			const response = await fetch(`${this.baseURL}/api/v1/entrance/loggedin`, {
				method: 'GET',
				mode: 'cors',
				headers: {},
				credentials: 'include',
				signal: controller.signal
			});

			if (response.status === 200) {
				localStorage.setItem('isAuthenticated', 'true');
				localStorage.setItem('authenticatedAt', Date.now().toString());
				return true;
			} else {
				localStorage.setItem('isAuthenticated', 'false');
				localStorage.setItem('authenticatedAt', '0');
				return false;
			}
		} catch(e) {
			console.error(e);
			// we assume that we're offline?
			if (isAuthenticated !== null && isAuthenticated === 'true') {
				return true;
			} else {
				return false;
			}
		}
		
	}

	public static async logout() {
		localStorage.setItem('isAuthenticated', 'false');
		localStorage.setItem('authenticatedAt', '0');
		
		const response = await fetch(`${this.baseURL}/api/v1/account/logout`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				//'Access-Control-Allow-Origin': 'http://10.8.0.10:8080'
			},
			credentials: 'include',
		});

		return true;
	}

	public static async registerUser(formData :FormData) :Promise<Response> {
		return fetch(`${this.baseURL}/api/v1/entrance/signup`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				//'Access-Control-Allow-Origin': 'http://10.8.0.10:8080'
			},
			credentials: 'include',
			body: formData
		});
	}

	public static async recover(user :string) {
		const formData = new FormData();
		formData.append('emailAddress', user);
		
		return fetch(`${this.baseURL}/api/v1/entrance/send-password-recovery-email`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				//'Access-Control-Allow-Origin': 'http://10.8.0.10:8080'
			},
			credentials: 'include',
			body: formData
		});
	}
}