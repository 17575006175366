
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton
} from "@ionic/vue";
import { defineComponent } from "vue";

import { personCircle, menuOutline } from "ionicons/icons";

import MainMenu from "./components/MainMenu.vue";

import DatabaseService from "../service/DatabaseService";
import { IAirport, IWaypoint } from "../service/DatabaseService";

export default defineComponent({
  name: "HomePage",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonMenuButton,
    MainMenu
  },
  setup() {
    DatabaseService.getWaypointByIdent('MOROK').then((wpt: IWaypoint | undefined) => {
      console.log(wpt);
    });

    DatabaseService.getAirportByIdent('EGKK').then((apt: IAirport | undefined) => {
      console.log(apt);
    });
    return { personCircle, menuOutline };
  },
  data() {
    return {};
  },
  methods: {
    oAuth: function() {
      console.log('pressed');
    }
  }
});
