
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonInput, IonDatetime, IonDatetimeButton,
  IonModal,
  IonGrid, IonRow, IonCol,
  IonSelect, IonSelectOption,
  modalController
} from "@ionic/vue";
import { defineComponent } from "vue";

import Flight from '../../../model/Flight';
import RouteparserService from '../../../service/RouteparserService';

import { format, parseISO } from 'date-fns';

export default defineComponent({
  name: "AddFlightManual",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonItem,
    IonLabel,
	IonInput, IonDatetime, IonDatetimeButton,
	IonModal,
	IonGrid, IonRow, IonCol,
	IonSelect, IonSelectOption,
  },
  data() {
	  return {
		  routing: '',
		  flight: new Flight()
	  };
  },
  watch: {
	  routing: async function(val, old) {
		  console.log(val);
		  
		  //this.flight = RouteparserService.parse(val);
		  const tempFlight :Flight = await RouteparserService.parse(val);
		  this.flight.setRouting(val, tempFlight.waypoints);
		  
		  if (tempFlight.depatureAirport.length > 0) {
		  	this.flight.depatureAirport = tempFlight.depatureAirport;
		  }
		  
		  if (tempFlight.destinationAirport.length > 0) {
		  	this.flight.destinationAirport = tempFlight.destinationAirport;
		  }
		  
		  console.log(this.flight);
	  },
	  'flight.std': function(val, old) {
		this.calculateFlighttime();
		this.calculateBlocktime();
	  },
	  'flight.sta': function(val, old) {
		this.calculateFlighttime();
		this.calculateBlocktime();
	  },
	  'flight.depatureAirport': function() {
	  	this.flight.depatureAirport = this.flight.depatureAirport.toUpperCase();
	  	this.flight.departureAirportCoords = RouteparserService.getAirportCoords(this.flight.depatureAirport);
	  },
	  'flight.destinationAirport': function() {
	  	this.flight.destinationAirport = this.flight.destinationAirport.toUpperCase();
	  	this.flight.destinationAirportCoords = RouteparserService.getAirportCoords(this.flight.destinationAirport);
	  }
  },
  methods: {
    cancel() {
      return modalController.dismiss(null, "cancel");
    },
    confirm() {
    	if ((this.$refs.flightplan as HTMLFormElement).checkValidity()) {
    	console.log('returning flightdata: ', this.flight);
    		return modalController.dismiss(this.flight, "confirm");
    	}
    },
    calculateFlighttime() {
    	/*
    	there's a bug with the timezones and the bloody ion-datetime component.
    	date.toISOString() is in Z and ion-datetime in
    	*/
    	const sta = new Date(this.flight.sta);
    	const staTime = sta.getTime() - sta.getTimezoneOffset()*60000;
    	const std = new Date(this.flight.std);
    	const stdTime = std.getTime() - std.getTimezoneOffset()*60000;
    	
    	console.log(parseISO(this.flight.sta), parseISO(this.flight.std));
    	const minutes = Math.round((sta.getTime() - new Date(this.flight.std).getTime())/60000);
    	console.table({
    		sta: new Date(parseISO(this.flight.sta)).getTime(),
    		std: new Date(parseISO(this.flight.std)).getTime(),
    		staS: this.flight.sta,
    		stdS: this.flight.std
    	});
		console.log(`time: ${minutes} or ${(staTime - stdTime)/60000}`);
		if (minutes < 0) return;

		const hours = Math.floor(minutes/60);
		const mins = minutes%60;

		let newFlighttime = new Date();
		newFlighttime.setUTCHours(hours, mins);

		this.flight.flighttimeDisplay = newFlighttime.toISOString();
		this.flight.flighttime = minutes;
    },
    calculateBlocktime() {
		const sta = new Date(this.flight.sta);
		const std = new Date(this.flight.std);
		const diff = (sta.getTime() - std.getTime())/60000;
		
		this.flight.blocktime = diff;
		this.flight.blocktimeDisplay = this.prettyTimer(diff);
	},
	prettyTimer(time :number) {
		if (time <= 0) {
			return '00:00';
		}

		let minutes = Math.floor(time / 60);
		let minutesString = '00';
		if (minutes < 10) {
			minutesString = '0' + minutes;
		} else {
			minutesString = '' + minutes;
		}

		let seconds = time % 60;
		let secondsString = '00';
		if (seconds < 10) {
			secondsString = '0' + seconds;
		} else {
			secondsString = '' + seconds;
		}

		return `${minutesString}:${secondsString}`;
	},
  }
});
