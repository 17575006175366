

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonMenuButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonGrid,
	IonRow,
	IonCol,
	IonFab,
	IonFabButton,
	IonFabList,
	IonIcon,
	IonBadge,
	actionSheetController,
	modalController,
	toastController,
	alertController
} from "@ionic/vue";

import { defineComponent } from "vue";

import {
	personCircle,
	menuOutline,
	addOutline,
	caretForwardCircle,
	airplaneOutline,
	calendarNumberOutline,
	clipboardOutline,
	close,
	heart,
	trash,
	share,
	chevronForwardOutline,
	chevronDownOutline,
	flashlightOutline,
	searchOutline
} from "ionicons/icons";

import MainMenu from "./components/MainMenu.vue";
import AddFlightManual from './components/flights/AddFlightManual.vue';
import AddFlightCalendar from './components/flights/AddFlightCalendar.vue';
import Flight from '../model/Flight';

export default defineComponent({
	name: "DutyPage",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonButtons,
		IonButton,
		IonMenuButton,
		IonCard,
		IonCardContent,
		IonCardHeader,
		IonCardSubtitle,
		IonCardTitle,
		IonGrid,
		IonRow,
		IonCol,
		IonFab,
		IonFabButton,
		IonFabList,
		IonIcon,
		IonBadge,
		MainMenu
	},
	setup() {
		let testWCurrent='',
			testWETA='',
			testp1='',
			testp2='';
		return {
			personCircle,
			menuOutline,
			addOutline,
			airplaneOutline,
			calendarNumberOutline,
			clipboardOutline,
			flashlightOutline,
			searchOutline,
			trash,
			chevronForwardOutline,
			chevronDownOutline,
			testWCurrent,testWETA,testp1,testp2
		};
	},
	data() {
		let flights: Flight[]=[];
		try {
			const data=localStorage.getItem('flights');
			if(data!==null) {
				let t=JSON.parse(data);
				for(let f of t) {
					flights.push(Flight.fromJSON(f));
				}
			}
		} catch(e) {
			console.error(e);
		}
		
		return {
			flights: flights,
			displayDetails: -1,
		}
	},
	ionViewDidEnter() {
		this.getWeatherForFlights();
	},
	methods: {
		toHourMinutes: (isoDate: string) => {
			console.log(isoDate);
			let d=new Date(isoDate);
			const hours=d.getHours()<10? '0'+d.getHours():''+d.getHours();
			const minutes=d.getMinutes()<10? '0'+d.getMinutes():''+d.getMinutes();

			return `${hours}:${minutes}`;
		},
		formatForecastTime(dt :number) {
		console.log(dt);
			let d = new Date(dt*1000);
			const hours=d.getUTCHours()<10? '0'+d.getUTCHours():''+d.getUTCHours();
			const minutes=d.getMinutes()<10? '0'+d.getMinutes():''+d.getMinutes();

			return `${hours}:${minutes}`;
		},
		toggleFlightInfo(id :number) {
			if (this.displayDetails === id) {
				this.displayDetails = -1;
			} else {
				this.displayDetails = id;
			}
		},
		getWeatherForFlights() {
		// update weather
			for (let flight of this.flights) {
				try {
					this.getCurrentWeather(flight.destinationAirportCoords[0], flight.destinationAirportCoords[1]).then((weather :any) => {
						console.log('weather: ',weather);
						/*iconID: null,
							temp: result.main.temp,
							qnh: result.main.pressure*/
							
							let degree = ''+weather.wind.deg;
							if (weather.wind.deg < 100) {
								degree = `0${weather.wind.deg}`;
							}
							
							flight.currentWeather = {
								icon: weather.weather[0].icon,
								temp: {
									temp: weather.main.temp.toFixed(0),
									min: weather.main.temp_min,
									max: weather.main.temp_max
								},
								wind: {
									speed: Number((weather.wind.speed * 1.94384).toFixed(0)),
									direction: degree
								},
								qnh: weather.main.pressure,
								visibility: weather.visibility
							}
					});
					
					this.getWeatherForcast(flight.destinationAirportCoords[0], flight.destinationAirportCoords[1]).then((forecasts :any[]) => {
						flight.weatherForecast = forecasts;
					});
						
				} catch(e) {
					console.error(e);
				}
			}
		},
		getCurrentWeather(lat: number, lon :number) :Promise<any> {
			// { lon: 7.52416457, lat: 47.587330984 }
			const api='bf29f2d584ac8292d9ede7da3eda2fcd';
			return fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${api}&units=metric`)
				.then((response) => response.json())
				.then(result => {
					console.log(result);
					/*
					let weather={
						iconID: null,
						temp: result.main.temp,
						qnh: result.main.pressure
					};

					if(result.weather.length>0) {
						weather.iconID=result.weather[0].id;
					}

					console.log(weather);
					if(weather.iconID!==null) {
						this.testWCurrent=weather.iconID;
					}
					//*/
					
					return result;
				});
		},
		getWeatherForcast(lat: number, lon :number) :Promise<any> {
			//const lat=47.587330984;
			//const lon=7.52416457;
			const api='bf29f2d584ac8292d9ede7da3eda2fcd';
			return fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=${api}&units=metric`)
				.then((response) => response.json())
				.then(response => {
					//console.log(result);

					let forecast=[];
					let result=response.list;

					for(let i=0;i<3;i++) {
						console.log(result[i]);
						/*
						let weather={
							time: result[i].dt,
							iconID: null,
							temp: result[i].main.temp,
							qnh: result[i].main.pressure
						};

						if(result[i].weather.length>0) {
							weather.iconID=result[i].weather[0].id;
						}

						forecast.push(weather);

						if(i===0&&weather.iconID!==null) {
							this.testWETA=weather.iconID;
						} else if(i===1&&weather.iconID!==null) {
							this.testp1=weather.iconID;
						} else if(i===2&&weather.iconID!==null) {
							this.testp2=weather.iconID;
						}
						//*/
						
						const weather = result[i];
						
						let degree = ''+weather.wind.deg;
						if (weather.wind.deg < 100) {
							degree = `0${weather.wind.deg}`;
						}
						
						let forecastWeather = {
							time: weather.dt,
							icon: weather.weather[0].icon,
							temp: {
								temp: weather.main.temp.toFixed(0),
								min: weather.main.temp_min,
								max: weather.main.temp_max
							},
							wind: {
								speed: Number((weather.wind.speed * 1.94384).toFixed(0)),
								direction: degree
							},
							qnh: weather.main.pressure,
							visibility: weather.visibility
						}
						
						forecast.push(forecastWeather);
					}

					console.log(forecast);
					return forecast;
					/*
					this.testWETA = forecast[0].iconID;
					this.testp1 = forecast[1].iconID;
					this.testp2 = forecast[2].iconID;
					//*/
				});
		},
		async presentToast(text: string) {
			const toast=await toastController.create({
				message: text,
				duration: 1500,
				position: 'top'
			});

			await toast.present();
		},
		async presentActionSheet() {
			const actionSheet=await actionSheetController.create({
				header: "Flights",
				cssClass: "my-custom-class",
				buttons: [
					{
						text: "Manual Entry",
						icon: clipboardOutline,
						role: 'manual',
						handler: () => {
							console.log("opening manual modal");
						}
					},
					{
						text: "Load Schedule",
						icon: calendarNumberOutline,
						role: 'calendar',
						data: "Data value",
						handler: () => {
							//this.presentToast('Not supported');
						}
					},
					{
						text: "By A/C Registration",
						icon: airplaneOutline,
						handler: () => {
							this.presentToast('Not supported');
						}
					},
					{
						text: "Clear All",
						role: "destructive",
						icon: trash,
						id: "delete-button",
						data: {
							type: "clear"
						},
						handler: async () => {
							const alert=await alertController.create({
								header: 'Do you really want to clear all flights?',
								buttons: [
									{
										text: 'No',
										role: 'cancel',
										handler: () => {
											//
										},
									},
									{
										text: 'Delete flights',
										role: 'confirm',
										cssClass: 'bg-danger',
										handler: () => {
											this.presentToast('Flights deleted');
											this.flights=[];
											localStorage.setItem('flights',JSON.stringify(this.flights));
										},
									},
								],
							});

							await alert.present();

						}
					},
					{
						text: "Cancel",
						icon: close,
						role: "cancel",
						handler: () => {
							console.log("Cancel clicked");
						}
					}
				]
			});
			await actionSheet.present();

			const { role,data }=await actionSheet.onDidDismiss();
			console.log("onDidDismiss resolved with role and data",role,data);

			if(role=='manual') {
				const modal=await modalController.create({
					component: AddFlightManual,
				});
				modal.present();

				const { data,role }=await modal.onWillDismiss();

				if(role==='confirm') {
					console.log(`flight data :`, data);
					this.flights.push(data);
					
					let weather = await this.getCurrentWeather(data.destinationAirportCoords[0], data.destinationAirportCoords[1]);
					console.log('weather: ',weather);
					/*iconID: null,
						temp: result.main.temp,
						qnh: result.main.pressure*/
						let degree = ''+weather.wind.deg;
							if (weather.wind.deg < 100) {
								degree = `0${weather.wind.deg}`;
							}
							
					data.currentWeather = {
						icon: weather.weather[0].icon,
						temp: {
							temp: weather.main.temp.toFixed(0),
							min: weather.main.temp_min,
							max: weather.main.temp_max
						},
						wind: {
							speed: Number((weather.wind.speed * 1.94384).toFixed(0)),
							direction: degree
						},
						qnh: weather.main.pressure,
						visibility: weather.visibility
					}
					
					let forecasts = await this.getWeatherForcast(data.destinationAirportCoords[0], data.destinationAirportCoords[1]);
					data.weatherForecast = forecasts;

					localStorage.setItem('flights',JSON.stringify(this.flights));
				}
			} else if (role === 'calendar') {
				// open calendar modal
				const modal=await modalController.create({
					component: AddFlightCalendar,
				});
				modal.present();

				const { data,role }=await modal.onWillDismiss();

				if(role==='confirm') {
					console.log(`flight data :`, data);
					for(let d of data) {
						let weather = await this.getCurrentWeather(d.destinationAirportCoords[0], d.destinationAirportCoords[1]);
						console.log('weather: ',weather);
						/*iconID: null,
							temp: result.main.temp,
							qnh: result.main.pressure*/
							let degree = ''+weather.wind.deg;
								if (weather.wind.deg < 100) {
									degree = `0${weather.wind.deg}`;
								}
								
						d.currentWeather = {
							icon: weather.weather[0].icon,
							temp: {
								temp: weather.main.temp.toFixed(0),
								min: weather.main.temp_min,
								max: weather.main.temp_max
							},
							wind: {
								speed: Number((weather.wind.speed * 1.94384).toFixed(0)),
								direction: degree
							},
							qnh: weather.main.pressure,
							visibility: weather.visibility
						}
						
						let forecasts = await this.getWeatherForcast(d.destinationAirportCoords[0], d.destinationAirportCoords[1]);
						d.weatherForecast = forecasts;
						this.flights.push(d);
					}
					localStorage.setItem('flights',JSON.stringify(this.flights));
				}
			}

			/*
			const lat=47.587330984;
			const lon=7.52416457;
			this.getCurrentWeather(lat, lon);
			//*/
			//this.getWeatherForcast('');
		},
	}
});
