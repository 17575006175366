import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ion-page" }
const _hoisted_2 = {
  key: 0,
  style: {"text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "medium",
                  onClick: _ctx.cancel
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Cancel")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Add Scheduled Flights "),
                _withDirectives(_createVNode(_component_ion_badge, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.addFlights.length), 1)
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.addFlights.length > 0]
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                (_ctx.addFlights.length > 1)
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      onClick: _ctx.confirm
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Add Flights")
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : (_ctx.addFlights.length === 1)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        onClick: _ctx.confirm
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Add Flight")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_title, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "floating" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              icon: _ctx.searchOutline,
                              slot: "start"
                            }, null, 8, ["icon"]),
                            _createTextVNode(" Search flights")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_input, {
                          modelValue: _ctx.searchTerm,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card_content, null, {
              default: _withCtx(() => [
                (!_ctx.flightsLoaded)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ion_spinner, { name: "circular" })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.flightsLoaded)
                  ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scheduledFlightsFiltered, (flight) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: flight.fa_flight_id,
                            color: _ctx.addFlights.includes(flight.fa_flight_id) ? 'success':'',
                            onClick: ($event: any) => (_ctx.toggleFlight(flight.fa_flight_id))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("b", null, _toDisplayString(flight.origin.code_icao), 1),
                                  _createTextVNode(" - "),
                                  _createElementVNode("b", null, _toDisplayString(flight.destination.code_icao), 1),
                                  _createTextVNode(" (" + _toDisplayString(flight.ident_icao) + ")", 1)
                                ]),
                                _: 2
                              }, 1024),
                              _withDirectives(_createVNode(_component_ion_badge, {
                                slot: "end",
                                color: "danger"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("+" + _toDisplayString(Math.floor(flight.departure_delay /60)) + "min", 1)
                                ]),
                                _: 2
                              }, 1536), [
                                [_vShow, flight.departure_delay > 600]
                              ]),
                              _withDirectives(_createVNode(_component_ion_badge, {
                                slot: "end",
                                color: "primary"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(flight.scheduled_out.replace('T', ' ').split(' ')[1]), 1)
                                ]),
                                _: 2
                              }, 1536), [
                                [_vShow, _ctx.depatureTimeDiff(flight.fa_flight_id) === 0]
                              ]),
                              _withDirectives(_createVNode(_component_ion_badge, {
                                slot: "end",
                                color: "warning"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(flight.scheduled_out.replace('T', ' ').split(' ')[1]), 1)
                                ]),
                                _: 2
                              }, 1536), [
                                [_vShow, _ctx.depatureTimeDiff(flight.fa_flight_id) > 0]
                              ])
                            ]),
                            _: 2
                          }, 1032, ["color", "onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}