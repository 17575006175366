import Waypoint from './Waypoint';
import { format, parseISO, differenceInMilliseconds } from 'date-fns';
import { FAFlight } from './Flightaware';

export default class Flight {
	public flightID = '';
	public flightnumber = '';
	public registration = '';
	public std = '';
	public sta = '';
	public flighttime = 0;
	public flighttimeDisplay = '';
	public blocktimeDisplay = '';
	public blocktime = 0;

	public securitySearch = 'tidy';

	public routing = '';
	public waypoints :Waypoint[];

	public depatureAirport = ''; // FIXME typo
	public departureAirportCoords :any = [];

	public destinationAirport = '';
	public destinationAirportCoords :any  = [];
	
	public alternate1 = '';
	public alternate2 = '';
	
	public currentWeather = {
		icon: '',
		temp: {
			temp: 0,
			min: 0,
			max: 0
		},
		wind: {
			speed: 0,
			direction: ''
		},
		qnh: 1013,
		visibility: 0
	};
	
	public weatherForecast :any[] = [];

	private flightawareData :FAFlight | undefined;

	constructor() {
		/* */
		const date = new Date();
		date.setUTCHours(0, 0, 0);

		this.flighttimeDisplay = date.toISOString();

		this.std = date.toISOString();
		this.sta = date.toISOString();
		
		this.waypoints = [];
	}

	setFlightawareData(flightawareData: FAFlight) {
		this.flightawareData = flightawareData;
	}

	getFlightawareData(key :string) {
		return (typeof this.flightawareData !== 'undefined' && this.flightawareData[key as keyof FAFlight]) || '';
	}

	/*
	constructor(flightnumber :string, registration :string, std :string, sta :string, flighttime :string) {
		this.flightnumber = flightnumber;
		this.registration = registration;
		this.std = std;
		this.sta = sta;
		this.flighttime = flighttime;
	}
	//*/

	setRouting(routing: string, waypoints: Waypoint[]) {
		this.routing = routing;
		this.waypoints = waypoints;
	}

	setBlocktime(blocktime: number) {
		this.blocktime = blocktime;
		this.blocktimeDisplay = this.prettyTimer(blocktime);
	}

	setFlightTime(flighttime: number) {
		const newFlighttime = new Date();
		newFlighttime.setUTCHours(Math.floor(flighttime/60), flighttime%60);

		console.log('Flighttime: ', newFlighttime.toISOString());
		console.log('Flighttime: ', flighttime);

		this.flighttimeDisplay = this.prettyTimer(flighttime);
		this.flighttime = flighttime;
	}

	prettyTimer(time :number) {
		if (time <= 0) {
			return '00:00';
		}

		const minutes = Math.floor(time / 60);
		let minutesString = '00';
		if (minutes < 10) {
			minutesString = '0' + minutes;
		} else {
			minutesString = '' + minutes;
		}

		const seconds = time % 60;
		let secondsString = '00';
		if (seconds < 10) {
			secondsString = '0' + seconds;
		} else {
			secondsString = '' + seconds;
		}

		return `${minutesString}:${secondsString}`;
	}

	static fromJSON(json :any) {
		const flight = new Flight();

		flight.flightID = json.flightID;
		flight.flightnumber = json.flightnumber;
		flight.registration = json.registration;
		flight.std = json.std;
		flight.sta = json.sta;
		flight.flighttime = json.flighttime;
		flight.flighttimeDisplay = json.flighttimeDisplay;
		flight.blocktimeDisplay = json.blocktimeDisplay;
		flight.blocktime = json.blocktime;
		flight.securitySearch = json.securitySearch;
		flight.routing = json.routing;
		flight.waypoints = json.waypoints;
		flight.depatureAirport = json.depatureAirport;
		flight.departureAirportCoords = json.departureAirportCoords;
		flight.destinationAirport = json.destinationAirport;
		flight.destinationAirportCoords = json.destinationAirportCoords;
		flight.alternate1 = json.alternate1;
		flight.alternate2 = json.alternate2;
		flight.currentWeather = json.currentWeather;
		flight.weatherForecast = json.weatherForecast;
		flight.flightawareData = json.flightawareData;

		return flight;
	}
}