import * as waypoints from '../../public/assets/data/waypoints.json';
import * as airports from '../../public/assets/data/airports.json';

import Flight from '../model/Flight';
import Waypoint from '../model/Waypoint';

import DatabaseService from './DatabaseService';
import { IWaypoint, IAirport } from './DatabaseService';

export default class RouteparserService {
	static async parse(route :string) :Promise<Flight> {
		const flight = new Flight();

		const parts = route.split(' ');
		const routing :Waypoint[] = [];

		for (let p of parts) {
			if (p.includes('/')) {
				p = p.split('/')[0];
			}

			console.log(`lookup ${p}`);
			const wpt = await DatabaseService.getWaypointByIdent(p);
			console.log('Found: ', wpt);

			if (typeof wpt !== 'undefined') {
				console.log(wpt);
				routing.push(new Waypoint(wpt.ident, wpt.lat, wpt.lon));
			}

			/*
			if (typeof waypoints[p as keyof Object] !== 'undefined') {
				console.log(waypoints[p as keyof Object]);
				//routing.push([waypoints[p as keyof Object]['lat' as keyof Object], waypoints[p as keyof Object]['lon' as keyof Object]]);
				routing.push(new Waypoint('', Number(waypoints[p as keyof Object]['lat' as keyof Object]), Number(waypoints[p as keyof Object]['lon' as keyof Object])));
			}//*/
		}

		// add airports
		const ADstart = parts[0].substring(0, 4);
		const ADend = parts[parts.length - 1].substring(0, 4);
		console.log(ADstart, ADend);

		if (typeof airports[ADstart as keyof Object] !== 'undefined') {
			const ap = await DatabaseService.getAirportByIdent(ADstart);

			if (ap !== undefined) {
				routing.unshift(new Waypoint(ap.ident, String(ap.lat), String(ap.lon)));

				console.log(ap);
				//routing.unshift([airports[ADstart as keyof Object]['lat' as keyof Object], airports[ADstart as keyof Object]['lon' as keyof Object]]);
				//routing.unshift(new Waypoint(ADstart, Number(airports[ADstart as keyof Object]['lat' as keyof Object]), Number(airports[ADstart as keyof Object]['lon' as keyof Object])));

				flight.depatureAirport = ap.ident;
				flight.departureAirportCoords = new Waypoint(ap.ident, String(ap.lat), String(ap.lon));//[ap.lat, ap.lon];
				//flight.departureAirportCoords = [airports[ADstart as keyof Object]['lat' as keyof Object], airports[ADstart as keyof Object]['lon' as keyof Object]];
			}
		}

		console.log(ADend, airports[ADend as keyof Object]);
		if (typeof airports[ADend as keyof Object] !== 'undefined') {
			const ap = await DatabaseService.getAirportByIdent(ADend);
			console.log(ap);

			if (ap !== undefined) {
				routing.push(new Waypoint(ap.ident, String(ap.lat), String(ap.lon)));
				flight.destinationAirport = ap.ident;
				flight.destinationAirportCoords = new Waypoint(ap.ident, String(ap.lat), String(ap.lon));//[ap.lat, ap.lon];
			}
			
			console.log(ap);
			//routing.push([airports[ADend as keyof Object]['lat' as keyof Object], airports[ADend as keyof Object]['lon' as keyof Object]]);
			/*
			routing.push(new Waypoint(ADend, Number(airports[ADend as keyof Object]['lat' as keyof Object]), Number(airports[ADend as keyof Object]['lon' as keyof Object])));

			flight.destinationAirport = ADend;
			flight.destinationAirportCoords = [airports[ADend as keyof Object]['lat' as keyof Object], airports[ADend as keyof Object]['lon' as keyof Object]];
			//*/
		}

		
		flight.setRouting(route, routing);

		console.log(flight);

		return flight;
	}
	
	static getAirportCoords(airport :string) :any[] {
		if (typeof airports[airport as keyof Object] !== 'undefined') {
			return [airports[airport as keyof Object]['lat' as keyof Object], airports[airport as keyof Object]['lon' as keyof Object]];
		}
		
		return [0,0];
	}
}