import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw, RouteLocationNormalized } from 'vue-router';
import AuthService from '@/service/AuthService';

import HomePage from '@/views/HomePage.vue';
import LoginPage from '@/views/LoginPage.vue';
import MapPage from '@/views/MapPage.vue';
import DutyPage from '@/views/DutyPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage // add lazy loading
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/views/SignupPage.vue'),
  },
  {
    path: '/forgot',
    name: 'RecoverPassword',
    component: () => import('@/views/RecoverPassword.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LoginPage,
    beforeEnter: async() => {
      console.log('calling beforeEnter');
      await AuthService.logout();

      return { name: 'Login' };
    }
  },
  {
    path: '/map/:id',
    name: 'Map',
    component: MapPage
  },
  {
    path: '/duty',
    name: 'Duty',
    component: DutyPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to :RouteLocationNormalized, from :RouteLocationNormalized) => {
  const target = ''+to.name?.toString();

  const isAuthenticated  = await AuthService.isAuthenticated();

  // not authenticated -> login page or singup
  if (!isAuthenticated && !['Login', 'Signup', 'RecoverPassword'].includes(target)/* (target !== 'Login' && target !== 'Signup')*/) {
    return { name: 'Login' }; // change to Login
  }

  if (isAuthenticated && ['Login', 'Signup'].includes(target)) {
    return {name: 'Home' };
  }
});

export default router
