export default class Waypoint {
	public name :string;
	public lat :number;
	public lon :number;

	constructor(name :string, lat :string, lon :string) {
		this.name = name;

		console.log('Waypoint: ', name, lat, lon);

		if (name === 'VEKEN') {
			this.lat = parseFloat(lat.replace('N', ''));
			this.lon = parseFloat(lon.replace('E', ''));
		} else {
			this.lat = this.long2cords(String(lat));
			this.lon = this.long2cords(String(lon));
		}

		console.log('Waypoint: ', name, this.lat, this.lon);
	}
	
	static convertArrayToWpt(name :string, coords :any[]) {
		return new Waypoint(name, coords[0], coords[1]);
	}

	long2cords(lon :string) {
		try {
			lon.includes('N');
		} catch (e) {
			console.log('Error: ', lon);
		}
		// + LAT=N/-LAT=S
		// +LONG=E/-LONG=W
		// '472347.70N', '0063919.80E'
		if (lon.includes('N') || lon.includes('S')) {
			const degree = parseInt(lon.substring(0,2));
			const minutes = parseInt(lon.substring(2,4));
			const seconds = parseFloat(lon.substring(4, lon.length-1));

	
			const decimal = degree + (minutes + (seconds/60))/60;
	
			//console.log(`N${degree}° ${minutes}' ${seconds} - ${decimal}`);
			return decimal;
		}
		else if(lon.includes('E') || lon.includes('W')) {
			const degree = parseInt(lon.substring(0,3));
			const minutes = parseInt(lon.substring(3,5));
			const seconds = parseFloat(lon.substring(5, lon.length-1));
	
			let decimal = degree + (minutes + (seconds/60))/60;
	
			if (lon.includes('W')) {
				decimal *= -1;
			}
	
			//console.log(`E${degree}° ${minutes}' ${seconds} - ${decimal}`);
			return decimal;
		}

		return parseFloat(lon);
	}
}